import { combineReducers } from 'redux';
import auth from './auth';
import error from './error';
import image from './image';

const rootReducer = combineReducers({ auth, error, image });

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
