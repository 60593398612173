import React, { useCallback, useState } from 'react';

import constants from '../statics/constants';
import useAuth from '../hooks/useAuth';
import Modal from '../components/Modal';

// local image
// import loaderImage from '../../src/images/loader.png';

/**
 * @description MyKeepin 어플을 즉시 불러오는 모바일 브라우저의 Main 패이지
 */
function MobilePage() {
  // Auth Redux 사용
  const { auth, startPolling } = useAuth();
  // Modal이 보여질지 여부 (디버그 모드로 실행 시 보이지 않음)
  const [showModal, setShowModal] = useState(!constants.debug);
  // MyKeepin을 여는동안 버튼을 누르지 못하게 하기 위함
  const [btnDisable, setBtnDisable] = useState(false);

  // 확인 버튼 클릭과 동시에 폴링이 시작되고, MyKeepin을 불러옴
  // 취소 버튼 클릭시 뒤로가기 실행
  const modalOnclick = useCallback(
    (result: boolean) => {
      if (result) {
        startPolling();
        setShowModal(false);
        setBtnDisable(true);
        setTimeout(() => setBtnDisable(false), 5000);
        window.location.href = auth.scheme_uri;
      } else window.history.back();
    },
    [auth.scheme_uri, startPolling],
  );

  return (
    <>
      <Modal
        text="위퍼블릭 월렛을 실행하시겠습니까?"
        onClick={modalOnclick}
        show={showModal}
      />
      <div className="mobile-page container mobile">
        <div className="wrapper box">
          <div className="mobile-service-title">
            <p className="text-title lsp-m04">
              <strong>{auth.title}</strong>의 인증 요청이 있습니다.
            </p>
          </div>
          <div className="mobile-loading">
            <img src="https://teststatic.metadium.com/images/web/loader.png" alt="Wepublic Wallet" />
            <p className="text-body bold lsp-m034">인증 처리 중입니다.</p>
            <p className="text-body bold lsp-m034">잠시만 기다려 주십시오.</p>
          </div>
          <button
            className="text-btn bold"
            type="button"
            tabIndex={0}
            onClick={() => modalOnclick(true)}
            disabled={btnDisable}
          >
            {btnDisable ? '위퍼블릭 월렛 실행 중' : '위퍼블릭 월렛 실행하기'}
          </button>
        </div>
      </div>
    </>
  );
}

export default MobilePage;
