import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../storage';
import * as storage from '../storage/image';
import { useCallback } from 'react';

/**
 * @description Image Redux에 대한 Custom Hooks
 */
export default function useImage() {
  /**
   * @description Image Store의 State
   * @type {storage.ImageState}
   */
  const image = useSelector((state: RootState) => state.image);
  const dispatch = useDispatch();

  /**
   * @description 고해상도 Header Background Image 로드하여 저해상도를 대체
   */
  // const onHeaderBg = useCallback(
  //   () => dispatch(storage.imageThunk(storage.HEADER_BG, image.header_bg)),
  //   [dispatch, image.header_bg],
  // );

  /**
   * @description 고해상도 Header Icon Image 로드하여 저해상도를 대체
   */
  const onHeaderIcon = useCallback(
    () => dispatch(storage.imageThunk(storage.HEADER_ICON, image.header_icon)),
    [dispatch, image.header_icon],
  );

  /**
   * @description 고해상도 Footer Icon Image 로드하여 저해상도를 대체
   */
  const onFooterIcon = useCallback(
    () => dispatch(storage.imageThunk(storage.FOOTER_ICON, image.footer_icon)),
    [dispatch, image.footer_icon],
  );

  /**
   * @description 고해상도 Loading Image 로드하여 저해상도를 대체
   */
  const onLoader = useCallback(
    () => dispatch(storage.imageThunk(storage.LOADER, image.loader)),
    [dispatch, image.loader],
  );

  /**
   * @description 고해상도 App Image 로드하여 저해상도를 대체
   */
  const onWepublicView = useCallback(
    () =>
      dispatch(storage.imageThunk(storage.WEPUBLIC_VIEW, image.wepublic_view)),
    [dispatch, image.wepublic_view],
  );

  // /**
  //  * @description 고해상도 MyKeepin Banner Image 로드하여 저해상도를 대체
  //  */
  // const onMykeepinBanner = useCallback(
  //   () =>
  //     dispatch(
  //       storage.imageThunk(storage.MYKEEPIN_BANNER, image.mykeepin_banner),
  //     ),
  //   [dispatch, image.mykeepin_banner],
  // );

  /**
   * @description 고해상도 Android Store Button Image 로드하여 저해상도를 대체
   */
  const onStoreAndroid = useCallback(
    () =>
      dispatch(storage.imageThunk(storage.STORE_ANDROID, image.store_android)),
    [dispatch, image.store_android],
  );

  /**
   * @description 고해상도 iOS Store Button Image 로드하여 저해상도를 대체
   */
  const onStoreIos = useCallback(
    () => dispatch(storage.imageThunk(storage.STORE_IOS, image.store_ios)),
    [dispatch, image.store_ios],
  );

  /**
   * @description 고해상도 Server Error Image 로드하여 저해상도를 대체
   */
  const onErrorServer = useCallback(
    () =>
      dispatch(storage.imageThunk(storage.ERROR_SERVER, image.error_server)),
    [dispatch, image.error_server],
  );

  /**
   * @description 고해상도 Client Error Image 로드하여 저해상도를 대체
   */
  const onErrorClient = useCallback(
    () =>
      dispatch(storage.imageThunk(storage.ERROR_CLIENT, image.error_client)),
    [dispatch, image.error_client],
  );

  return {
    image,
    check: storage.check,
    // onHeaderBg,
    onHeaderIcon,
    onFooterIcon,
    onLoader,
    onWepublicView,
    // onMykeepinBanner,
    onStoreAndroid,
    onStoreIos,
    onErrorServer,
    onErrorClient,
  };
}
