import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';


type AppQrProps = {
  scheme_uri: string;
  limit_itme?: number;
};

/**
 * @description QR을 생성하여 제한 시간까지 남은 시간과 함께 보여 주는 컴포넌트
 * @property {string} scheme_uri QR을 생성할 URI
 * @property {number | undefined} limit_itme 제한 시간
 */
function AppQr({ scheme_uri, limit_itme }: AppQrProps) {
  // 남은 시간을 MM:SS로 표한한 문자열
  // const [time, setTime] = useState<string>(getRemainTime(limit_itme));
  // // 1초마다 남은 시간이 계산되는 Interval
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  // //1초마다 남은 시간을 계산해 MM:SS 포멧으로 time에 저장
  // useEffect(() => {
  //   if (limit_itme) {
  //     const interval = setInterval(() => {
  //       const remain = getRemainTime(limit_itme);
  //       if (remain === '00:00') clearInterval(interval);
  //       setTime(remain);
  //     }, 1000);
  //     setTimer(interval);
  //   }
  // }, [limit_itme]);

  // @description 컴포넌트가 사라지면 interval을 제거
  useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timer]);

  //limit_time이 존재하면 남은 시간을 표시
  // const timerView = useMemo(() => {
  //   return (
  //     limit_itme && (
  //       <p className="text-sub-title">
  //         유효시간 <strong>{time}</strong>
  //       </p>
  //     )
  //   );
  // }, [limit_itme, time]);

  return (
    <div className="app-qr">
      <QRCode value={scheme_uri} size={160} />
      {/* {timerView} */}
    </div>
  );
}

export default AppQr;
