import React, { useMemo, useEffect, useState } from 'react';
import classNames from 'classnames';

import useImage from '../hooks/useImage';
import useError from '../hooks/useError';
import constants from '../statics/constants';

type ErrorPageProps = {
  mobile?: boolean;
};

/**
 * @description Error 페이지
 * @param {boolean} mobile 모발일 브라우저인지 여부
 */
function ErrorPage({ mobile = false }: ErrorPageProps) {
  // Error Redux 사용
  const { error } = useError();
  // Image Redux 사용
  const {
    image,
    check,
    onErrorServer,
    onErrorClient,
    // onMykeepinBanner,
  } = useImage();

  // 에러 타입에 맞게 Title과 Content 설정
  const errorMessage = useMemo(() => {
    // Note: 1: 서버 오류
    // Note: 2: 클라이언트 오류
    // Note: 3: Rate Limit 오류
    if (error.type === 0) throw new Error('Error type cannot be 0');
    const { title, subtitle, content } = constants.errorMessage[error.type];

    const titleComp = title;
    const subComp = subtitle;
    const contentComp = content.map((str, index) => (
      <span key={index} className='text-body'>{str}</span>
    ));

    return { titleComp, subComp, contentComp };
  }, [error.type]);

  // 에러 타입에 맞게 Error Icon 설정
  // error_client와 error_server 동일한 이미지 사용 중(23.7.26)
  const iconComp = useMemo(() => {
    const src = error.type === 2 ? image.error_client : image.error_server;
    return <img src={src} alt="Error icon" />;
  }, [error.type, image.error_client, image.error_server]);


  const [containerClass, setContainerClass] = useState('error-page container');

  // mobile check
  useEffect(() => {
    const updatedClass = classNames('error-page container', { 'mobile-page': mobile });
    setContainerClass(updatedClass);
  }, [mobile])

  // 고해상도 이미지 랜더링
  useEffect(() => {
    if (error.type === 2 && !check(image.error_client)) onErrorClient();
    else if (!check(image.error_server)) onErrorServer();
    // if (!mobile && !check(image.mykeepin_banner)) onMykeepinBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.type]);

  return (
    <div className={containerClass}>
      <div className="wrapper box">
        <div className="error-service-title">
          <p className="text-title">
            <strong>{errorMessage.titleComp}</strong>
          </p>
          <p className='text-info'>{errorMessage.subComp}</p>
        </div>
        <div className="error-content">
          {iconComp} 
          {errorMessage.contentComp}
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
