import React, { useEffect, useState } from 'react';

import constants from '../statics/constants';
import useImage from '../hooks/useImage';
import DownloadQr from '../components/DownloadQr';
import classNames from 'classnames';

type downloadpageProps = {
  mobile?: boolean;
}

/**
 * @description MyKeepin 다운로드를 위한 페이지
 */
function DownloadPage({ mobile = false }: downloadpageProps) {
  // Image Redux사용
  const { image, check, onStoreAndroid, onStoreIos } = useImage();
  const [containerClass, setContainerClass] = useState(
    'download-page container',
  );

  // mobile check
  useEffect(() => {
    const updatedClass = classNames('download-page container', {
      'mobile-page': mobile,
    });
    setContainerClass(updatedClass);
  }, [mobile]);

  const exitFun = () => {
    window.close();
  }

  // 고해상도 이미지 랜더링
  useEffect(() => {
    if (!check(image.store_android)) onStoreAndroid();
    if (!check(image.store_ios)) onStoreIos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={containerClass}>
      <div className="wrapper box">
        <div className="download-title">
          <p className="ic-exit">
            <span onClick={exitFun}>
              <img src={image.ic_exit} alt="exit" />
            </span>
          </p>
          <p className="text-info text-center">
            스마트폰으로 QR코드를 스캔하면 설치 화면으로 이동합니다.
          </p>
        </div>
        <DownloadQr
          qr_img={image.qr_code}
          android={constants.app_store.android}
          android_btn={image.store_android}
          ios={constants.app_store.ios}
          ios_btn={image.store_ios}
        />
      </div>
    </div>
  );
}

export default DownloadPage;
