/**
 * @description 현재 디바이스가 모바일인지 확인합니다.
 * @return {boolean} 모바일이면 true, 아니면 false
 */
export function isMobile() {
  const userAgent = navigator.userAgent;
  return (
    userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|IEMobile/i) !== null
  );
}

/**
 * @description 정해진 시간까지 남은 시간을 mm:ss 형식으로 출력합니다.
 * @param {number} limit 제한 시간(ms)
 * @return {string} 남은 시간(mm:ss)
 */
export function getRemainTime(limit: number | undefined) {
  if (limit === undefined) return '00:00';

  const remain = limit - Date.now();
  if (remain < 1000) return '00:00';

  const s = Math.floor(remain / 1000) % 60;
  const m = Math.floor(remain / 60000);

  const secText = s < 10 ? '0' + s : s;
  const minText = m < 10 ? '0' + m : m;

  return `${minText}:${secText}`;
}
