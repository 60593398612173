import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import Thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

import rootReducer from './storage';
import { AuthState } from './storage/auth';
import { ErrorState } from './storage/error';
import constants from './statics/constants';
import App from './App';
import * as serviceWorker from './serviceWorker';

// 서버에서 생성한 인증코드 값이 HTML Head 안에 Inline Script로 window.__PRELOADED_STATE__에 넣음
// 서버에서 생성한 에러 값이 HTML Head 안에 Inline Script로 window.__PRELOADED_ERROR__에 넣음
declare global {
  interface Window {
    __PRELOADED_STATE__: AuthState | undefined;
    __PRELOADED_ERROR__: ErrorState | undefined;
  }
}

// 설정한 유효시간을 사용해 만료시간 계산
if (window.__PRELOADED_STATE__ !== undefined)
  window.__PRELOADED_STATE__.limit_time =
    Date.now() + constants.limit_min * 60000;

// 서버로부터 받은 데이터를 활용해 Redux를 생성
const store = createStore(
  rootReducer,
  { auth: window.__PRELOADED_STATE__, error: window.__PRELOADED_ERROR__ },
  applyMiddleware(Thunk),
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
