import { createAction, ActionType, createReducer } from 'typesafe-actions';

// Note: 1: 서버 오류
// Note: 2: 클라이언트 오류
// Note: 3: Rate Limit 오류
export type ErrorState = {
  type: 0 | 1 | 2 | 3;
  title?: string;
};

const initialState: ErrorState = { type: 0 };

/* Types */
//Error Store의 State를 파라미터로 전달한 객체로 초기화
export const INIT_STATE = 'error/INIT_STATE';

/* Actions */
export const initState = createAction(INIT_STATE)<ErrorState>();
const actions = { initState };
type ErrorAction = ActionType<typeof actions>;

//Error Store의 State를 파라미터로 전달한 객체로 초기화
const error = createReducer<ErrorState, ErrorAction>(initialState).handleAction(
  initState,
  (state, action) => {
    const type = [1, 2, 3].includes(action.payload.type)
      ? action.payload.type
      : 1;
    const title = action.payload.title ? action.payload.title : '알 수 없음';

    return { ...state, type, title };
  },
);

export default error;
