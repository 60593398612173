import React, { useEffect, useMemo } from 'react';

import useAuth from './hooks/useAuth';
import useError from './hooks/useError';
import { isMobile } from './utils/util';
import { redirect, errorRedirect } from './utils/network';

import FrameLayout from './layouts/FrameLayout';
// import TabLayout from './layouts/TabLayout';
import AuthPage from './pages/AuthPage';
// import DownloadPage from './pages/DownloadPage';
import MobilePage from './pages/MobilePage';
import ErrorPage from './pages/ErrorPage';

import './styles/main.scss';
import DownloadPage from './pages/DownloadPage';

/**
 * @description Application
 */
function App() {
  // Auth Redux 사용
  const { auth, startPolling } = useAuth();
  // Error Redux 사용
  const { error, onErrorInit } = useError();
  // 현재 유저 환경이 모바일인지 여부
  const mobile = useMemo(() => isMobile(), []);

  // 에러가 없고, 모바일이 아니면 자동으로 폴링을 시작합니다.
  useEffect(() => {
    if (error.type === 0 && !mobile && auth.polling_uri) startPolling();
  }, [auth.polling_uri, error.type, mobile, startPolling]);

  // 폴링이 성공하거나 실패하면 리다이렉션 시킵니다.
  useEffect(() => {
    if (auth.polling_result === true)
      redirect(auth.redirect_uri, auth.code, auth.state);
    if (auth.polling_result === false)
      errorRedirect(
        auth.redirect_uri,
        auth.state,
        auth.polling_data?.error,
        auth.polling_data?.message,
      );
  }, [auth.code, auth.polling_data, auth.polling_result, auth.redirect_uri, auth.state]);

  // userAgent의 디바이스 종류에따라 알맞은 화면을 보여줍니다.
  const view = useMemo(() => {
    const noErr = error.type === 0;

    // 서버로 받아온 데이터가 잘못되었을 경우
    if (noErr && !auth.code) {
      onErrorInit({ type: 2 });
      return null;
    }

    // 모바일일 경우
    if (mobile) return auth.download ? <DownloadPage mobile={true} /> : noErr ? <MobilePage /> : <ErrorPage mobile={true} />;
  

    // 데스크탑인 경우
    return auth.download ? <DownloadPage /> : noErr ? <AuthPage /> : <ErrorPage />;

    // const titleList = ['MYKEEPiN 인증', 'MYKEEPiN 다운로드'];
    // const view = noErr ? <AuthPage /> : <ErrorPage />;
    // const pageList = [view, <DownloadPage />];
    // return <TabLayout titleList={titleList} pageList={pageList} />;
    
  }, [auth.code, auth.download, error.type, mobile, onErrorInit]);

  return (
    <div className="application">
      <FrameLayout mobile={mobile}>{view}</FrameLayout>
    </div>
  );
}

export default App;
