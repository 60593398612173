import React from 'react';

type InformationProps = {
  icon: string;
  icon2: string;
  alt: string;
  link: string;
  desc?: Array<string | React.ReactNode>;
  info?: Array<string>;
  copy: string;
};

/**
 * @description Footer에 들어가는 회사 정보 컴포넌트
 * @property {string} icon 회사 로고
 * @property {string} alt 로고 이미지 alt 값
 * @property {string} link 로고 이미지 클릭시 이동 경로
 * @property {string | Array<string | React.ReactNode>} desc 회사 설명
 * @property {Array<string>} info 회사 정보
 * @property {string} copy Copy Write
 */
function Information({ icon, icon2, alt, link, desc, info, copy }: InformationProps) {

  return (
    <div className="information-wrapper wrapper">
      <div className="footer-top">
        <a id="icon" href={link} target="_blank">
          <img src={icon} alt={alt} />
        </a>
        <span>Powered by<img src={icon2} alt="Powered by CPLABS" /></span>
      </div>
      <div className="footer-bottom">
        <p className="text-caption" id="copywrite">
        {copy}
        </p>
        <a href="https://wepublichelp.zendesk.com/hc/ko" target="_blank">Support Center</a>
      </div>
    </div>
  );
}

export default Information;
