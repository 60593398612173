import { useSelector, useDispatch } from 'react-redux';
import { initState, AuthState, pollingThunk } from '../storage/auth';
import { useCallback } from 'react';

import { RootState } from '../storage';
import constants from '../statics/constants';

/**
 * @description Auth Redux에 대한 Custom Hooks
 */
export default function useAuth() {
  /**
   * @description Auth Store의 State
   * @type {AuthState}
   */
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  /**
   * @description Auth Store의 State를 파라미터로 전달한 객체로 초기화
   * @param {AuthState} auth 초기화에 사용할 객체 데이터
   */
  const onAuthInit = useCallback(
    (auth: AuthState) => dispatch(initState(auth)),
    [dispatch],
  );

  /**
   * @description Auth Status를 가져오는 Polling 작업을 비동기 액션(Thunk) 실행
   */
  const startPolling = useCallback(() => {
    if (!constants.debug) dispatch(pollingThunk(auth.polling_uri));
  }, [auth.polling_uri, dispatch]);

  return {
    auth,
    onAuthInit,
    startPolling,
  };
}
