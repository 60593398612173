import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../storage';
import { initState, ErrorState } from '../storage/error';
import { useCallback } from 'react';

/**
 * @description Error Redux에 대한 Custom Hooks
 */
export default function useError() {
  /**
   * @description Error Store의 State
   * @type {ErrorState}
   */
  const error = useSelector((state: RootState) => state.error);
  const dispatch = useDispatch();

  /**
   * @description Error Store의 State를 파라미터로 전달한 객체로 초기화
   * @param {ErrorState} error 초기화에 사용할 객체 데이터
   */
  const onErrorInit = useCallback(
    (error: ErrorState) => dispatch(initState(error)),
    [dispatch],
  );

  return {
    error,
    onErrorInit,
  };
}
