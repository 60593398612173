import React, { useEffect, useMemo, useState } from 'react';

import useAuth from '../hooks/useAuth';
import useImage from '../hooks/useImage';
import NumberingContent from '../components/NumberingContent';
import AppQr from '../components/AppQr';
// image local
// import stap1Image from '../../src/images/step_1.png';
// import qrIcon from '../../src/images/ic_scan.png';
import { getRemainTime } from '../utils/util';

/**
 * @description 인증 QR 코드를 보여주는 Main 페이지
 */
function AuthPage() {
  // Auth Redux사용
  const { auth } = useAuth();
  // Image Redux사용
  const { image, check, onWepublicView } = useImage();

  // 남은 시간을 MM:SS로 표한한 문자열
  const [time, setTime] = useState<string>(getRemainTime(auth.limit_time));
  // 1초마다 남은 시간이 계산되는 Interval
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  //1초마다 남은 시간을 계산해 MM:SS 포멧으로 time에 저장
  useEffect(() => {
    if (auth.limit_time) {
      const interval = setInterval(() => {
        const remain = getRemainTime(auth.limit_time);
        if (remain === '00:00') clearInterval(interval);
        setTime(remain);
      }, 1000);
      setTimer(interval);
    }
  }, [auth.limit_time]);

  //limit_time이 존재하면 남은 시간을 표시
  const timerView = useMemo(() => {
    return (
      auth.limit_time && (
        <p className="text-sub-title time-limit lsp-m032">
          유효시간 <strong>{time}</strong>
        </p>
      )
    );
  }, [auth.limit_time, time]);

  // @description 컴포넌트가 사라지면 interval을 제거
  useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timer]);

  // 고해상도 이미지 랜더링
  useEffect(() => {
    if (!check(image.wepublic_view)) onWepublicView();
    // if (!check(image.mykeepin_banner)) onMykeepinBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="auth-page container">
      <div className="wrapper box">
        <div className="auth-service-title">
          <p className="text-title lsp-m048">
            {/* <strong className="text-title-large">{auth.title}</strong> */}
            <strong>{auth.title}</strong>의 인증 요청이 있습니다.
          </p>
          <div className="app-download">
            <span>위퍼블릭 월렛 앱으로 인증해주세요.</span>
            {/* download page로 이동 */}
            <a href="/didauth/v1/download" target="_blank">
              다운로드
            </a>
          </div>
        </div>
        <div className="auth-content">
          <NumberingContent
            number={1}
            title="위퍼블릭 월렛 앱 상단에서"
            icon={image.ic_scan}
            title_bold="QR스캔 버튼 선택"
          >
            <img src={image.wepublic_view} alt="Application top bar" />
          </NumberingContent>
          <NumberingContent
            number={2}
            title="우측의"
            title_bold="QR코드 스캔"
            limit_time={timerView}
          >
            <AppQr scheme_uri={auth.scheme_uri} limit_itme={auth.limit_time} />
          </NumberingContent>
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
