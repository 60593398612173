import React, { useMemo } from 'react';
import classNames from 'classnames';

type ModalProps = {
  text: string;
  show: boolean;
  onClick: (result: boolean) => void;
};

/**
 * @description App Browser에서 MyKeepin을 실행할지를 묻는 팝업 Modal
 * @property {string} text Modal Text
 * @property {boolean} show Modal이 보여질지 여부
 * @property {(result: boolean) => void} onClick Modal Button Onclick
 */
function Modal({ text, onClick, show }: ModalProps) {
  //모달이 보여질지 여부에따라 클래스 설정하여 style 변경
  const containerClass = useMemo(
    () => classNames('modal-container', { hide: !show }),
    [show],
  );

  return (
    <div className={containerClass}>
      <div className="modal-wrapper">
        <div className="modal">
          <p className="text-sub-title">{text}</p>
          <div id="buttons">
            <button
              className="text-body bold"
              type="button"
              tabIndex={0}
              onClick={() => onClick(false)}
            >
              취소
            </button>
            <button
              className="text-body bold"
              type="button"
              tabIndex={0}
              onClick={() => onClick(true)}
            >
              열기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
