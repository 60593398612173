import { createAction, ActionType, createReducer } from 'typesafe-actions';
import { ThunkAction } from 'redux-thunk';

import constants from '../statics/constants';
import { polling, pollingData } from '../utils/network';

export type AuthState = {
  code: string;
  state: string;
  title: string;
  redirect_uri: string;
  scheme_uri: string;
  polling_uri: string;
  limit_time?: number;
  polling_result?: boolean;
  polling_data?: pollingData;
  download?: boolean;
};

const initialState: AuthState = {
  code: '',
  state: '',
  title: '',
  redirect_uri: '',
  scheme_uri: '',
  polling_uri: '',
  limit_time: Date.now() + constants.limit_min * 60000,
  download: false,
};

/* Types */
// Auth Store의 State를 파라미터로 전달한 객체로 초기화
export const INIT_STATE = 'auth/INIT_STATE';
// Auth Status를 가져오는 Polling 작업을 비동기 액션(Thunk) 성공
export const POLLING_SUCCESS = 'auth/POLLING_SUCCESS';
// Auth Status를 가져오는 Polling 작업을 비동기 액션(Thunk) 실패
export const POLLING_ERROR = 'auth/POLLING_ERROR';

/* Actions */
export const initState = createAction(INIT_STATE)<AuthState>();
export const pollingSuccess = createAction(POLLING_SUCCESS)<pollingData>();
export const pollingError = createAction(POLLING_ERROR)<pollingData>();
const actions = { initState, pollingSuccess, pollingError };
type AuthAction = ActionType<typeof actions>;

// Auth Status를 가져오는 Polling 작업을 비동기 액션(Thunk)
type PollingThunkAction = ThunkAction<void, AuthState, null, AuthAction>;
export function pollingThunk(url: string): PollingThunkAction {
  return async dispatch => {
    try {
      const result = await polling(url);
      dispatch(pollingSuccess(result));
    } catch (err) {
      dispatch(pollingError(err));
    }
  };
}

const auth = createReducer<AuthState, AuthAction>(initialState)
  // Auth Store의 State를 파라미터로 전달한 객체로 초기화
  .handleAction(initState, (state, action) => ({ ...state, ...action.payload }))
  // Auth Status를 가져오는 Polling 작업을 비동기 액션(Thunk) 성공
  .handleAction(pollingSuccess, (state, action) => ({
    ...state,
    polling_result: true,
    polling_data: action.payload,
  }))
  // Auth Status를 가져오는 Polling 작업을 비동기 액션(Thunk) 실패
  .handleAction(pollingError, (state, action) => ({
    ...state,
    polling_result: false,
    polling_data: action.payload,
  }));

export default auth;
