import React from 'react';

type NumberingContentProps = {
  number: number;
  title: string;
  title_bold: string;
  icon?: string;
  limit_time?: any;
  children: React.ReactNode;
};

/**
 * @description Number 인덱스가 붙은 리스트 Item
 * @property {number} number 인덱스 Number
 * @property {string} title Item 제목
 * @property {string} title_bold Item 제목 뒷부분 (Bold)
 * @property {React.ReactNode} children Item Content
 */
function NumberingContent({
  number,
  title,
  title_bold,
  icon,
  limit_time,
  children,
}: NumberingContentProps) {

  return (
    <div className="numbering-content">
      <div className="numbering-content-title">
        <span className="text-number">{number}.</span> 
        <p className="text-body lsp-m034">{title} <span>{icon && <span className='icon'><img src={icon} alt='qr 아이콘' /></span>} {title_bold}</span>
          {limit_time && limit_time}</p>
      </div>
      <div className="numbering-content-content">{children}</div>
    </div>
  );
}

export default NumberingContent;
