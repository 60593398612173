import axios, { AxiosResponse, AxiosError } from 'axios';

import constants from '../statics/constants';

/**
 * @description 폴링 결과 데이터
 */
export type pollingData = {
  success: boolean;
  error?: string;
  message?: string;
};

/**
 * @description polling에서 사용할 Axios 설정을 반환
 * @param {string} url 서버로부터 받아온 polling_uri
 * @return {object} axoio에서 사용할 설정 값
 */
function getPollingNetwork(url: string) {
  const method: 'get' = 'get';
  const headers = { 'Cache-Control': 'no-store, no-cache, must-revalidate' };

  return { url, method, headers };
}

/**
 * @description 폴링 주기를 조절. maintain 시간이 지나고부터 slow 시간을 주기로 interval time 단위로 요청을 필터링
 * @param {object} data polling 시작 시간과, 현재 polling count를 담은 객체
 * @returns {boolean} 현재 네트워크 요청을 진행해도 되는지 여부
 */
function pollingFilter(data: { start: number; count: number }) {
  // 변경 시간을 초과한 시간 계산
  const diff = Date.now() - data.start;
  if (diff > constants.limit_min * 60 * 1000) return true;

  // 변경 시간을 초과한 시간 계산
  const lateTime = diff - constants.polling.maintain;
  if (lateTime <= 0) return true;

  // 요청 지연 갱신 횟수 계산
  const limitCount = Math.ceil(lateTime / constants.polling.slow);

  // 시도 횟수가 시간 변경 요청 지연 갱신 횟수와 같다면 네트워크 요청하고, count를 0으로 초기화
  // 1번 초과 => 2번당 1번 요청 (interval time이 1초일 경우 2초당 한번 요청)
  // 2번 초과 => 3번당 1번 요청 (interval time이 1초일 경우 3초당 한번 요청)
  const isLimit = data.count === limitCount;
  data.count = isLimit ? 0 : data.count + 1;
  return isLimit;
}

/**
 * @description 설정한 시간 주기로 인증 데이터 상태값을 확인하는 폴링을 시작
 * @param {string} url 서버로부터 받아온 polling_uri
 * @return {Promise<pollingData>} 인증 데이터의 status 값
 */
export function polling(url: string): Promise<pollingData> {
  const config = getPollingNetwork(url);
  // Interval Polling이 끝날 때까지 비동기 실행
  return new Promise((resolve, rejects) => {
    // 폴링 주기를 조절하는데 사용할 데이터
    const data = { start: Date.now(), count: 0 };
    const interval = setInterval(() => {
      // 폴링 주기 조절
      if (pollingFilter(data)) {
        axios(config)
          .then((res: AxiosResponse) => {
            // 요청이 성공하고 인증 데이터가 사인 됐으면 success 반환
            if (res.data.data.status === 1) {
              clearInterval(interval);
              resolve({ success: true });
            }
          })
          .catch((err: AxiosError) => {
            clearInterval(interval);
            // 요청 실패시 응답 데이터가 있으면 error와 message 세팅
            if (err.response && err.response.data) {
              rejects({
                success: false,
                error: err.response.data.code,
                message: err.response.data.message,
              });
            } else {
              rejects({
                success: false,
                error: 40900,
                message: 'User interrupt'
              });
            }
          });
      }
    }, constants.polling.interval);
  });
}

/**
 * @description 해당 이미지의 Storage 주소를 반환
 * @param {string} name Storage에서 가져올 이미지 파일 이름
 * @param {string} stage 이미지가 저해상와 고해상도로 나뉘어 있을 시 해상도 선택
 */
export function getImageUrl(name: string, stage?: 'low' | 'high'): string {
  let url = constants.image.url + name;
  // 이미지 이름에 헤상도 suffix 삽입
  if (stage && constants.image.stage[stage] !== undefined) {
    const arr = url.split('.');
    arr[arr.length - 2] += constants.image.stage[stage];
    url = arr.join('.');
  }
  return url;
}

/**
 * @description 고해상도 이미지 url을 저해상도 이미지 url로, 저해상도 이미지 url을 고해상도 이미지 url로 변경
 * @param {string} url 이미지 Storage URL
 * @param {string} to 변경할 해상도
 */
export function convertImageUrl(url: string, to: 'low' | 'high'): string {
  const from = to === 'low' ? 'high' : 'low';
  const arr = url.split('.');
  arr[arr.length - 2] = arr[arr.length - 2].replace(
    constants.image.stage[from],
    constants.image.stage[to],
  );
  return arr.join('.');
}

/**
 * @description Image 렌더링에서 사용할 Axios 설정을 반환
 * @param {string} url 이미지 URL
 * @return {object} axoio에서 사용할 설정 값
 */
function getImageRenderingNetwork(url: string) {
  const method: 'get' = 'get';
  const responseType: 'arraybuffer' = 'arraybuffer';

  return { url, method, responseType };
}

/**
 * @description 전달받은 URL에서 이미지를 받아 base64 string으로 반환합니다.
 * @param {string} url 이미지 URL
 * @return {Promise<string>} base64 string 이미지를 반환한는 Promise
 */
export function imageRendering(url: string): Promise<string> {
  const config = getImageRenderingNetwork(url);
  return axios(config).then((res: AxiosResponse) => {
    // ASCII 코드 문자열로 변경
    const unit8 = new Uint8Array(res.data).reduce(
      (prev, cur) => prev + String.fromCharCode(cur),
      '',
    );
    const base64 = btoa(unit8);
    return `data:;base64,${base64}`;
  });
}

/**
 * @description 전달받은 URI에 Code와 State를 Query Parameter로 붙여 리다이렉션 시킵니다.
 * @param {string} redirect_uri Redirection 시킬 SP의 URI
 * @param {string} code 인증 Code
 * @param {string} state 인승 State
 */
export const redirect = (redirect_uri: string, code: string, state: string) => {
  const prefix = redirect_uri.includes('?') ? '&' : '?';
  window.location.replace(
    `${redirect_uri}${prefix}state=${state}&code=${code}`,
  );
};

/**
 * @description 전달받은 URI에 Error와 Message를 Query Parameter로 붙여 리다이렉션 시킵니다.
 * @param {string} redirect_uri Redirection 시킬 SP의 URI
 * @param {string} _error 폴링 실패시 결과로 받은 Error
 * @param {string} _message 폴링 실패시 결과로 받은 Message
 */
export const errorRedirect = (
  redirect_uri: string,
  state: string,
  _error: string | undefined,
  _message: string | undefined,
) => {
  const error = _error || 50000;
  const message = _message || 'Unknown Server Error';
  const prefix = redirect_uri.includes('?') ? '&' : '?';
  window.location.replace(
    `${redirect_uri}${prefix}error=${error}&message=${message}&state=${state}`,
  );
};
