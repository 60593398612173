import { createAction, createReducer } from 'typesafe-actions';
import { ThunkAction } from 'redux-thunk';

import { getImageUrl, convertImageUrl, imageRendering } from '../utils/network';

export type ImageState = {
  header_bg: string;
  header_icon: string;
  footer_icon: string;
  loader: string;
  wepublic_view: string;
  ic_scan: string,
  pc_illust: string, 
  ic_arrow_right: string,
  cp_icon: string,

  mykeepin_banner: string;
  store_android: string;
  store_ios: string;
  error_server: string;
  error_client: string;
  ic_exit: string;
  qr_code: string;
};

// 각 이미지들의 저해상도 즈소로 State 초기화
const initialState: ImageState = {
  header_bg: getImageUrl('header_bg.png', 'low'), // 사용안함
  header_icon: getImageUrl('header_icon.png', 'low'),
  footer_icon: getImageUrl('footer_icon.png', 'low'),
  loader: getImageUrl('loader.png'),
  wepublic_view: getImageUrl('wepublic_view.png', 'low'),
  ic_scan: getImageUrl('ic_scan.png', 'low'),
  pc_illust: getImageUrl('pc_illust.png', 'low'),
  ic_arrow_right: getImageUrl('ic_arrow_right.png', 'low'),
  cp_icon: getImageUrl('cplabs_logo.png', 'low'),

  mykeepin_banner: getImageUrl('mykeepin_banner.jpg', 'low'), // 사용안함
  store_android: getImageUrl('store_android.png', 'low'),
  store_ios: getImageUrl('store_ios.png', 'low'),
  error_server: getImageUrl('ic_error.png', 'low'),
  error_client: getImageUrl('ic_error.png', 'low'),
  ic_exit: getImageUrl('ic_exit.png', 'low'),
  qr_code: getImageUrl('qr_code.png', 'low'),
};

/* Types */
// 고해상도 Header Background Image 로드하여 저해상도를 대체
export const HEADER_BG = 'image/HEADER_BG';
// 고해상도 Header Icon Image 로드하여 저해상도를 대체
export const HEADER_ICON = 'image/HEADER_ICON';
// 고해상도 Footer Icon Image 로드하여 저해상도를 대체
export const FOOTER_ICON = 'image/FOOTER_ICON';
// 고해상도 Loading Image 로드하여 저해상도를 대체
export const LOADER = 'image/LOADER';
// 고해상도 MyKeepin App Bar Image 로드하여 저해상도를 대체
export const WEPUBLIC_VIEW = 'image/STEP_1';
export const IC_SCAN = 'image/IC_SCAN';
export const PC_ILLUST = 'image/PC_ILLUST';
export const IC_ARROW_RIGHT = 'image/IC_ARROW_RIGHT';
export const CP_ICON = 'iamge/CP_ICON';
// 고해상도 MyKeepin Banner Image 로드하여 저해상도를 대체
// export const MYKEEPIN_BANNER = 'image/MYKEEPIN_BANNER';
// 고해상도 Android Store Button Image 로드하여 저해상도를 대체
export const STORE_ANDROID = 'image/STORE_ANDROID';
// 고해상도 iOS Store Button Image 로드하여 저해상도를 대체
export const STORE_IOS = 'image/STORE_IOS';
// 고해상도 Server Error Image 로드하여 저해상도를 대체
export const ERROR_SERVER = 'image/ERROR_SERVER';
// 고해상도 Client Error Image 로드하여 저해상도를 대체
export const ERROR_CLIENT = 'image/ERROR_CLIENT';

export const IC_EXIT = "image/IC_EXIT";
export const QR_CODE = "image/QR_CODE";

/* Actions */
type ImageAction = { type: string; payload: string };

// 고해상도 이미지를 로드하여 저해상도 이미지를 대체하는 비동기 액션(Thunk)
type ImageThunkAction = ThunkAction<void, ImageState, null, ImageAction>;
export function imageThunk(type: string, url: string): ImageThunkAction {
  // 액션 객체 생성
  const action = createAction(type)<string>();
  return async dispatch => {
    try {
      // 저해상도 이미지 주소를 고해상도 이미지 주소 생성
      const converted = convertImageUrl(url, 'high');
      const image = await imageRendering(converted);
      dispatch(action(image));
    } catch (err) {
      dispatch(action(''));
    }
  };
}

/**
 * @description 저해상도 이미지는 URL 형식이고, 고해상도 이미지는 base64 형식. 이를 가지고 고해상도인지 구분
 * @param {string} str 이미지 주소
 * @returns {string | boolean} 고새상도면 그대로 주소를 반환, 고해상도 주소가 아니면 False 반환
 */
export const check = (str: string) =>
  str.startsWith('data:;base64,') ? str : false;

const image = createReducer<ImageState, ImageAction>(initialState, {
  // 고해상도 Header Background Image 로드하여 저해상도를 대체
  [HEADER_BG]: (state, action) => {
    const header_bg = check(action.payload) || state.header_bg;
    return { ...state, header_bg };
  },
  // 고해상도 Header Icon Image 로드하여 저해상도를 대체
  [HEADER_ICON]: (state, action) => {
    const header_icon = check(action.payload) || state.header_icon;
    return { ...state, header_icon };
  },
  // 고해상도 Footer Icon Image 로드하여 저해상도를 대체
  [FOOTER_ICON]: (state, action) => {
    const footer_icon = check(action.payload) || state.header_icon;
    return { ...state, footer_icon };
  },
  // 고해상도 Loading Image 로드하여 저해상도를 대체
  [LOADER]: (state, action) => {
    const loader = check(action.payload) || state.loader;
    return { ...state, loader };
  },
  // 고해상도 MyKeepin App Bar Image 로드하여 저해상도를 대체
  [WEPUBLIC_VIEW]: (state, action) => {
    const wepublic_view = check(action.payload) || state.wepublic_view;
    return { ...state, wepublic_view };
  },
  [IC_SCAN]: (state, action) => {
    const ic_scan = check(action.payload) || state.ic_scan;
    return { ...state, ic_scan };
  },
  [PC_ILLUST]: (state, action) => {
    const pc_illust = check(action.payload) || state.pc_illust;
    return { ...state, pc_illust };
  },
  [IC_ARROW_RIGHT]: (state, action) => {
    const ic_arrow_right = check(action.payload) || state.ic_arrow_right;
    return { ...state, ic_arrow_right };
  },
  [CP_ICON]: (state, action) => {
    const cp_icon = check(action.payload) || state.cp_icon;
    return { ...state, cp_icon };
  },
  // 고해상도 MyKeepin Banner Image 로드하여 저해상도를 대체
  // [MYKEEPIN_BANNER]: (state, action) => {
  //   const mykeepin_banner = check(action.payload) || state.mykeepin_banner;
  //   return { ...state, mykeepin_banner };
  // },
  // 고해상도 Android Store Button Image 로드하여 저해상도를 대체
  [STORE_ANDROID]: (state, action) => {
    const store_android = check(action.payload) || state.store_android;
    return { ...state, store_android };
  },
  // 고해상도 iOS Store Button Image 로드하여 저해상도를 대체
  [STORE_IOS]: (state, action) => {
    const store_ios = check(action.payload) || state.store_ios;
    return { ...state, store_ios };
  },
  // 고해상도 Server Error Image 로드하여 저해상도를 대체
  [ERROR_SERVER]: (state, action) => {
    const error_server = check(action.payload) || state.error_server;
    return { ...state, error_server };
  },
  // 고해상도 Client Error Image 로드하여 저해상도를 대체
  [ERROR_CLIENT]: (state, action) => {
    const error_client = check(action.payload) || state.error_client;
    return { ...state, error_client };
  },
  [IC_EXIT]: (state, action) => {
    const ic_exit = check(action.payload) || state.ic_exit;
    return { ...state, ic_exit };
  },
  [QR_CODE]: (state, action) => {
    const qr_code = check(action.payload) || state.qr_code;
    return{ ...state, qr_code }
  }
});

export default image;
