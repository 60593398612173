import React from 'react';
// local image
// import Header_logo from '../images/header_icon.png';

type TitleProps = {
  icon: string;
  alt?: string;
  link: string;
  // title: string;
};

/**
 * @description Header에 들어가는 Application Title 컴포넌트
 * @property {string} icon Title Logo 경로
 * @property {string | undefined} alt Title Icon 이미지 alt 값
 * @property {string} link 로고 클릭시 이동할 경로
//  * @property {string} title Application 제목
 */
function Title({ icon, alt, link}: TitleProps) {
  return (
    <div className="title-wrapper wrapper">
      <a id="icon" href={link} target="_blank">
        <img src={icon} alt={alt} />
      </a>
      {/* <p className="text-sub-title">{title}</p> */}
    </div>
  );
}

export default Title;
