import React from 'react';
// import QRCode from 'qrcode.react';

type DownloadQrProps = {
  qr_img: string;
  ios: string;
  ios_btn: string;
  android: string;
  android_btn: string;
};

/**
 * @description App Storer로 이동하는 QR을 생성하여 보여주는 컴포넌트
 * @property {string} qr_img qr img
 * @property {string} ios iOS App Store Url
 * @property {string} ios_btn iOS Button Icon Url
 * @property {string} android Android App Store Url
 * @property {string} android_btn Android Button Icon Url
 */
function DownloadQr({
  qr_img,
  ios,
  ios_btn,
  android,
  android_btn,
}: DownloadQrProps) {
  return (
    <div className="download-component">
      <div className="download-qr">
        <div id="qr">
          <img src={qr_img} alt="download qr" />
          {/* <QRCode value={android ? android : ios} size={178} /> */}
        </div>
        {/* <div id="qr">
          <QRCode value={ios} size={112} />
        </div> */}
      </div>
      <div className="download-btns">
        <a
          id="link"
          href={ios}
          target="_blank"
          rel="noopener noreferrer"
          className="text-body"
        >
          <img src={ios_btn} alt="iOS icon" /> App Store
        </a>
        <a
          id="link"
          href={android}
          target="_blank"
          rel="noopener noreferrer"
          className="text-body"
        >
          <img src={android_btn} alt="iOS icon" /> Google Play
        </a>
      </div>
    </div>
  );
}

export default DownloadQr;
