export default {
  // 디버그 모드
  debug: false,

  // 인증 제한 시간
  limit_min: 10,

  // 폴링 설정
  polling: {
    interval: 1000, // 폴링 주기
    maintain: 300000, // 초기 폴링 주기를 유지하는 기간
    slow: 30000, // 초기 폴링 유지시간을 지나고부터 주기가 느려지는 주기
  },

  // 이미지 Storage 설정
  image: {
    url: 'https://static.mykeepin.com/images/web2/', // storage 주소
    // url: 'https://teststatic.metadium.com/images/web/',
    stage: { low: '_low', high: '_high' }, // 저해상도와 고해상도 이미지의 suffix
  },

  // MyKeepin 홈페이지. 로고 클릭시 이동 링크 사용
  homepage: 'https://www.wepublic.com/wallet',

  // MyKeepin App Store 주소
  app_store: {
    android:
      'https://play.google.com/store/apps/details?id=com.coinplug.mykeepin',
    ios:
      'https://apps.apple.com/kr/app/mykeepin-%EC%BD%94%EC%9D%B8%ED%94%8C%EB%9F%AC%EA%B7%B8-%EB%94%94%EC%A7%80%ED%84%B8-id/id1479166844',
  },

  // 에러 타입별 title과 content
  errorMessage: {
    1: {
      title: ['오류가 발생했습니다.', '잠시 후 다시 시도해 주세요.'],
      subtitle: [''],
      content: [''],
    },
    2: {
      title: ['알 수 없는 인증 요청입니다.'],
      subtitle: ['잘못된 요청에 대해서는\n인증 서비스를 지원하지 않습니다.'],
      content: [
        '계속해서 동일한 문제가 발생하는 경우\n홈페이지를 통해 문의해 주세요.',
      ],
    },
    3: {
      title: ['인증 요청 제한'],
      subtitle: ['너무 잦은 인증 요청으로 인해 접근이 제한되었습니다.'],
      content: ['잠시 후 다시 시도해 주세요.'],
    },
  },
};
