import React, { useMemo, useEffect } from 'react';
import classNames from 'classnames';

import constants from '../statics/constants';
import useImage from '../hooks/useImage';

import Title from '../components/Title';
import Information from '../components/Information';

// local image(temporary)
// import footer_icon from '../../src/images/footer_icon.png';
// import cp_icon from '../../src/cplabs_logo_low.png';

type FrameProps = {
  children: React.ReactNode;
  mobile: boolean;
};
/**
 * @description Application 전체를 Header, Body, Footer로 나누는 Latout 컴포넌트
 * @property {React.ReactNode} children Body에 들어갈 Content Component
 * @property {boolean} mobile 모바일 브라우저인지 여부
 */
function Frame({ children, mobile }: FrameProps) {
  // Image Redux사용
  const { image, check, onHeaderIcon, onFooterIcon } = useImage();

  // mobile인지에 따라 Header와 Footer에 Class를 추가하여 Style 변경
  const headerClass = useMemo(
    () => classNames('header container', { mobile }),
    [mobile],
  );
  const footerClass = useMemo(
    () => classNames('footer container', { mobile }),
    [mobile],
  );

  // 고해상도 이미지 랜더링
  useEffect(() => {
    if (!check(image.header_icon)) onHeaderIcon();
    if (!check(image.footer_icon)) onFooterIcon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={headerClass}>
        <Title
          icon={image.header_icon}
          alt="Wepublic Wallet"
          link={constants.homepage}
          // title="똑똑한 관리의 시작!"
        />
      </div>
      <div className="body">{children}</div>
      <div className={footerClass}>
        <Information
          icon={image.footer_icon}
          icon2={image.cp_icon}
          alt="Wepublic Wallet"
          link={constants.homepage}
          copy="Copyright &copy; CPLABS, Inc. All rights reserved."
        />
      </div>
    </>
  );
}

export default Frame;
